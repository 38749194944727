// Variable overrides
$primary: #3081c3;
$dark-primary: #0a0b18;
$lightDark-primary: #2c2c34;
.bg-darktheme {
    background-color: #0a0b18;
}
.bg-lightdark {
    background-color: 	#2f353a !important;
    color: white;
}
.bg-xlightdark {
    background-color: #4c4f54 !important;
    color: white;
}